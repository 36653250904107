import React, { Fragment } from 'react';

export default function CGU() {
  return <Fragment>
    <div className="container-mini">
      <h1>Conditions générales d'utilisation</h1>
      <div class="text-cg-wrapper">
        <h2>1)	Propriété des contenus</h2>
        <p>Le fait d'accéder à la Web app et aux contenus téléchargeables ne donne pas le droit de les utiliser sans l'accord des Sociétés d’Economie Alpestre : tous les droits de reproduction sont réservés en vertu de l'article L.122-4 du code de la Propriété intellectuelle.</p>
        <p>Toute utilisation des ressources en dehors du cadre autorisé (photographiques, iconographiques, textuelles ou relatives à la forme, la mise en page, la structure, les logotypes et les documents et ressources téléchargeables...) présentes sur ce site doit faire l'objet d'une autorisation express des Sociétés d’Economie Alpestre.</p>
        <p>À titre principal, les Société d’Economie Alpestre concèdent une autorisation de visualisation du contenu du site dans le cadre de la mise en œuvre de l’opération pédagogique scolaire « Pilou et Fleurette en alpage ».</p>
        <h2>2)	Accès strictement personnel</h2>
        <p>Le code d’accès personnalisé et associé à votre adresse mail vous a été transmis par la Société d’Economie Alpestre. Il est strictement personnel et ne peut être transmis à des tiers personnes. Si la Société d’Economie Alpestre constate une utilisation inappropriée ou abusive de votre connexion, elle se verra dans l’obligation de supprimer l’accès qui vous a été transmis. </p>
        <h2>3)	Accès limité dans le temps</h2>
        <p>Le code d’accès personnalisé et associé à votre adresse mail vous permet de vous connecter autant de fois que vous le souhaitez à la Web app. Il est valable à partir de la date où il vous a été transmis et durant toute l’année scolaire en cours. </p>
      </div>
      <div className="mentions-legales-randonneurs"><img src="/images/randonneurs.png" aria-hidden={true} alt="" /></div>
      <img src="/images/chien.png" className="perso-footer perso-footer-chien" aria-hidden={true} alt="" />
    </div>
  </Fragment>
}
